import request from '@/plugin/axios';
import setting from '@/setting';
var hostPath = setting.appApi;

var pos = function pos(host, action, data) {
  if (!data) {
    data = {};
  }

  return request({
    url: host + action,
    method: 'post',
    data: data
  });
};

export function accountCodeBind(data) {
  return pos(hostPath.base, 'tw/bind', data);
}
export function accountLogin(data) {
  return pos(hostPath.base, 'account/login', data);
}
export function accountList(data) {
  return pos(hostPath.base, 'account', data);
}
export function accountAdd(data) {
  return pos(hostPath.base, 'account/handle', data);
}
export function accountDel(data) {
  return pos(hostPath.base, 'account/delete', data);
}
export function menuList(data) {
  return pos(hostPath.base, 'system/menu', data);
}
export function menuBatch(data) {
  return pos(hostPath.base, 'system/menu/batch/handle', data);
}
export function menuAdd(data) {
  return pos(hostPath.base, 'system/menu/handle', data);
}
export function menuDel(data) {
  return pos(hostPath.base, 'system/menu/delete', data);
}
export function roleList(data) {
  return pos(hostPath.base, 'account/roles', data);
}
export function roleAdd(data) {
  return pos(hostPath.base, 'account/roles/handle', data);
}
export function roleDel(data) {
  return pos(hostPath.base, 'account/roles/delete', data);
}
export function slideList(data) {
  //新闻
  return pos(hostPath.base, 'slide', data);
}
export function slideAdd(data) {
  //新闻
  return pos(hostPath.base, 'slide/handle', data);
}
export function slideDel(data) {
  //新闻
  return pos(hostPath.base, 'slide/delete', data);
}
export function gameList(data) {
  //新闻
  return pos(hostPath.base, 'games', data);
}
export function gameAdd(data) {
  //新闻
  return pos(hostPath.base, 'games/handle', data);
}
export function gameDel(data) {
  //新闻
  return pos(hostPath.base, 'games/delete', data);
}
export function contactList(data) {
  //新闻
  return pos(hostPath.base, 'contact', data);
}
export function contactAdd(data) {
  //新闻
  return pos(hostPath.base, 'contact/handle', data);
}
export function contactDel(data) {
  //新闻
  return pos(hostPath.base, 'contact/delete', data);
}
export function newsList(data) {
  //新闻
  return pos(hostPath.base, 'news', data);
}
export function newsAdd(data) {
  //新闻
  return pos(hostPath.base, 'news/handle', data);
}
export function newsDel(data) {
  //新闻
  return pos(hostPath.base, 'news/delete', data);
}