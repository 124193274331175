import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.sort";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "core-js/modules/es.string.sub";
import _toConsumableArray from "/Users/suifeng/Downloads/sxGameWeb/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import { menuList, menuAdd, menuDel, menuBatch } from '../../../../api/apis';
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch
  },
  data: function data() {
    var _this = this;

    return {
      menuList: [],
      tableModel_1000: {
        "border": true,
        "defaultExpandAll": false,
        "del": false,
        "headerButton": [{
          "id": "",
          "tag": "add",
          "title": "添加主菜单",
          "type": "primary"
        }, {
          "id": "",
          "tag": "default",
          "title": "均匀排序",
          "type": null
        }],
        "id": "id_1000",
        "isTree": true,
        "rowButton": [{
          "id": "",
          "tag": "row-add",
          "title": "添加",
          "type": "success"
        }, {
          "id": "",
          "tag": "edit",
          "title": "编辑",
          "type": "primary"
        }, {
          "id": "",
          "tag": "del",
          "title": "删除",
          "type": "danger"
        }, {
          "id": "",
          "tag": "default",
          "title": "上",
          "type": null
        }, {
          "id": "",
          "tag": "default",
          "title": "下",
          "type": null
        }],
        "rowKey": "id",
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableCascaderProps": {},
        "tableModel": [{
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "菜单ID",
          "required": 0,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "id"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "父级id",
          "required": 1,
          "rowAddField": "id",
          "rules": [],
          "show": 0,
          "type": "select",
          "width": "300",
          "prop": "pid"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "图标",
          "required": 0,
          "rules": [],
          "show": 1,
          "type": "icon",
          "width": "120",
          "prop": "icon"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "名称",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 120,
          "prop": "menu_name"
        }, {
          "add": 1,
          "defaultValue": "/index",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "路由",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "action"
        }, {
          "add": 1,
          "defaultValue": "pages",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "控制器",
          "required": 0,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "controller"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "路由名称",
          "required": 0,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "module"
        }, {
          "add": 1,
          "defaultValue": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "排序",
          "required": 0,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 80,
          "prop": "sort"
        }, {
          "add": 0,
          "defaultValue": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "子管理员是否可用",
          "required": 0,
          "rules": [],
          "show": 0,
          "type": "select",
          "width": "150",
          "prop": "access"
        }, {
          "add": 1,
          "defaultValue": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "是否菜单",
          "required": 0,
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "150",
          "prop": "is_show"
        }, {
          "add": 0,
          "detail": 0,
          "excel": 0,
          "groupId": "",
          "label": "",
          "required": 0,
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "params"
        }],
        "tableName": "t_system_menus",
        "tableSelectProps": {
          "pid": {
            "data": "[{\"key\":\"\",\"value\":\"\"}]",
            "id": 0,
            "multiple": 0,
            "relation": false,
            "relationId": "",
            "remote": true,
            "remoteFunc": "tableFunc_select_pid"
          },
          "is_show": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 2,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "access": {
            "data": [{
              "key": 0,
              "value": "不可用"
            }, {
              "key": 1,
              "value": "可用"
            }],
            "id": 3,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableSwitchProps": {},
        "title": "菜单",
        "treeProps": {
          "children": "sub",
          "hasChildren": "hasChildren"
        },
        "type": "tableTemplate",
        "tableRadioProps": {}
      },
      remoteFunc: {
        tableFunc_select_pid: function tableFunc_select_pid(tag, relationData, resolve) {
          //筛选项模型数组
          //[{ key: "新品", value: "0" }]
          var data = [{
            key: 0,
            value: '根菜单'
          }];

          var menu = _this.treeToList(_this.menuList, 'sub');

          for (var i in menu) {
            data.push({
              key: menu[i].id,
              value: menu[i].longName
            });
          }

          resolve(tag, data);
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1000;
  },
  computed: {},
  methods: {
    treeToList: function treeToList(tree, children) {
      var ary = [];

      var addRow = function addRow(obj, children, parentName) {
        var item = JSON.parse(JSON.stringify(obj));

        if (parentName === '') {
          item.longName = item.menu_name;
        } else {
          item.longName = parentName + '-' + item.menu_name;
        }

        delete item[children];
        ary.push(item);
        var sub = obj[children];

        for (var i in sub) {
          addRow(sub[i], children, item.longName);
        }
      };

      for (var i in tree) {
        addRow(tree[i], children, '');
      }

      return ary;
    },
    hideCreator: function hideCreator(res) {
      var loopDeep = function loopDeep(ary) {
        for (var i in ary) {
          var item = ary[i];
          loopDeep(item.sub);
        }
      };

      loopDeep(res);
    },
    reqTableData: function reqTableData(params, resolve) {
      var _this2 = this;

      menuList({}).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this2.hideCreator(res);

                _this2.menuList = res;
                resolve({
                  total: 0,
                  data: res
                });

              case 3:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      }

      menuAdd(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      menuDel({
        id: ids[0]
      }).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      //点击列表按钮回调
      if (this.tableModel_1000.rowButton[btnIndex].title === '上') {
        this._moveRow(row, 0);
      } else if (this.tableModel_1000.rowButton[btnIndex].title === '下') {
        this._moveRow(row, 1);
      }
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {
      var _this3 = this;

      //点击列表头部按钮回调
      var data = [];

      if (btnIndex === 1) {
        var sortMenu = function sortMenu(obj, index) {
          data.push({
            id: obj.id,
            sort: index
          });

          for (var i = 0; i < obj.sub.length; i++) {
            sortMenu(obj.sub[i], i);
          }
        };

        for (var i = 0; i < this.menuList.length; i++) {
          sortMenu(this.menuList[i], i);
        }

        var params = {
          data: data,
          duplicate: ['sort']
        };
        console.log('params', params);
        menuBatch(params).then(function _callee4(res) {
          return regeneratorRuntime.async(function _callee4$(_context4) {
            while (1) {
              switch (_context4.prev = _context4.next) {
                case 0:
                  _this3.table.reloadData();

                  _this3.$message({
                    message: '排列成功',
                    type: 'success'
                  });

                case 2:
                case "end":
                  return _context4.stop();
              }
            }
          });
        });
      }
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = data.header;
        var results = data.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    resort: function resort(menus) {
      var index = 0;

      var find = function find(children) {
        for (var i in children) {
          children[i].sort = index++;
          find(children[i].sub);
        }
      };

      find(menus);
      var data = [];

      var push = function push(children) {
        for (var i in children) {
          data.push({
            id: children[i].id,
            sort: children[i].sort
          });
          push(children[i].sub);
        }
      };

      push(menus);
      return data;
    },
    _menuPeerRows: function _menuPeerRows(pid) {
      var find = function find(ary) {
        if (ary.length <= 0) {
          return null;
        }

        for (var i in ary) {
          var obj = ary[i];

          if (obj.id === pid) {
            return obj.sub;
          }

          var ret = find(obj.sub);

          if (ret) {
            return ret;
          }
        }
      };

      if (pid === 0) {
        return this.menuList;
      }

      return find(this.menuList);
    },
    _moveRow: function _moveRow(row, direction) {
      var _this4 = this;

      var menus = this._menuPeerRows(row.pid);

      var rowIndex = -1;
      var toIndex = -1;

      if (!menus) {
        return;
      }

      for (var i = 0; i < menus.length; i++) {
        if (menus[i].id === row.id) {
          rowIndex = i;
          break;
        }
      }

      if (direction === 0) {
        //上
        if (rowIndex === 0) {
          toIndex = menus.length - 1;
          menus.splice(rowIndex, 1);
          menus.push(row);
        } else {
          toIndex = rowIndex - 1;
          menus.splice.apply(menus, [rowIndex, 1].concat(_toConsumableArray(menus.splice(toIndex, 1, menus[rowIndex]))));
        }
      } else {
        //下
        if (rowIndex === menus.length - 1) {
          toIndex = 0;
          menus.splice(rowIndex, 1);
          menus.unshift(row);
        } else {
          toIndex = rowIndex + 1;
          menus.splice.apply(menus, [rowIndex, 1].concat(_toConsumableArray(menus.splice(toIndex, 1, menus[rowIndex]))));
        }
      }

      var data = this.resort(this.menuList);
      var params = {
        data: data,
        duplicate: ['sort']
      };
      menuBatch(params).then(function _callee5(res) {
        return regeneratorRuntime.async(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                _this4.table.reloadData();

                _this4.$message({
                  message: '排列成功',
                  type: 'success'
                });

              case 2:
              case "end":
                return _context5.stop();
            }
          }
        });
      });
    }
  }
};