import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempForm from '../tempForm';
export default {
  components: {
    tempForm: tempForm
  },
  props: {
    title: {
      type: String,
      required: true
    },
    tableGroupProps: {
      type: Array,
      required: true
    },
    tableModel: {
      type: Array,
      required: true
    },
    width: {
      type: String,
      default: '60%'
    },
    remoteFunc: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    switchProps: {
      type: Object,
      default: function _default() {
        return {
          activeValue: '1',
          inactiveValue: '0'
        };
      }
    },
    radioProps: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    selectProps: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    cascaderProps: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    onSubmit: {
      type: Function,
      default: function _default() {
        return null;
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      isEdit: false,
      dialogVisible: false,
      activeName: '',
      formMode: [],
      formData: []
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (!val) {
        this.clearForm();
      }
    }
  },
  created: function created() {},
  methods: {
    clearForm: function clearForm() {
      var _this2 = this;

      this.isEdit = false;
      this.loading = false;
      this.$nextTick(function () {
        for (var i in _this2.formData) {
          var ref = 'form' + _this2.formData[i].groupId;

          _this2.$refs[ref][0].reloadForm(null);
        }
      });
    },
    show: function show(isEdit) {
      var _this3 = this;

      var row = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      this.row = row;
      var tmp = [];
      this.activeName = this.tableGroupProps[0].id + '';

      for (var m in this.tableGroupProps) {
        var groupId = this.tableGroupProps[m].id;
        var groupName = this.tableGroupProps[m].name;
        var group = [];

        for (var j in this.tableModel) {
          var model = this.tableModel[j];

          if (model.groupId != groupId) {
            continue;
          }

          if (!model.add) {
            continue;
          }

          console.log('show', groupId, model.prop, model);
          var formItem = {};
          formItem.required = model.required;
          formItem.label = model.label;
          formItem.type = model.type;
          formItem.prop = model.prop;
          formItem.desc = model.desc;
          formItem.rules = model.rules;
          formItem.min = model.min;
          formItem.max = model.max;
          formItem.relation = model.relation;
          formItem.relationProps = model.relationProps;
          formItem.exProps = {};

          if (model.defaultValue != null) {
            formItem.defaultValue = model.defaultValue;
          } else {
            formItem.defaultValue = '';
          }

          if (!isEdit) {
            if (row) {
              if (model.rowAddField && model.rowAddField != '') {
                formItem.defaultValue = row[model.rowAddField];
              }
            }
          }

          if (model.type === this.$tempModel.showType.image || model.type === this.$tempModel.showType.images || model.type === this.$tempModel.showType.avatar || model.type === this.$tempModel.showType.video || model.type === this.$tempModel.showType.audio) {
            formItem.exProps = model.file;
          } else if (model.type === this.$tempModel.showType.select) {
            formItem.exProps = this.selectProps[model.prop];
          } else if (model.type === this.$tempModel.showType.switch) {
            formItem.exProps = this.switchProps[model.prop];
          } else if (model.type === this.$tempModel.showType.cascader) {
            formItem.exProps = this.cascaderProps[model.prop];
          } else if (model.type === this.$tempModel.showType.radio) {
            formItem.exProps = this.radioProps[model.prop];
          }

          group.push(formItem);
        }

        tmp.push({
          groupName: groupName,
          groupData: group,
          groupId: groupId + ''
        });
      }

      this.formData = tmp;
      this.isEdit = isEdit;
      this.dialogVisible = true;
      this.$nextTick(function () {
        for (var i in _this3.formData) {
          var ref = 'form' + _this3.formData[i].groupId;

          _this3.$refs[ref][0].reloadForm(_this3.isEdit ? _this3.row : null);
        }
      });
    },
    clickSubmit: function clickSubmit() {
      var _this4 = this;

      var _this = this;

      var forms = {};

      var checkRules = function checkRules(index, suc) {
        var refName = 'form' + _this.tableGroupProps[index].id;
        var obj = _this.$refs[refName][0];
        obj.submitForm(function (valid, form) {
          if (!valid) {
            _this.activeName = _this.tableGroupProps[index].id + '';
            return;
          }

          for (var key in form) {
            forms[key] = form[key];
          }

          if (index + 1 <= _this.tableGroupProps.length - 1) {
            checkRules(index + 1, suc);
          } else {
            suc();
          }
        });
      };

      checkRules(0, function () {
        if (_this4.onSubmit) {
          _this4.loading = true;
          new Promise(function (resolve) {
            _this4.onSubmit(_this4.isEdit, forms, _this4.row, resolve);
          }).then(function (error) {
            _this4.loading = false;

            if (!error) {
              _this4.dialogVisible = false;

              _this4.$message({
                type: 'success',
                message: _this4.isEdit ? '编辑成功' : '添加成功'
              });
            }
          });
        }
      });
    }
  }
};