var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.form,
        rules: _vm.rules,
        "label-width": _vm.labelWidth,
        "status-icon": true
      }
    },
    [
      _vm._l(_vm.formModel, function(item, index) {
        return _c(
          "el-form-item",
          {
            attrs: { label: item["label"], prop: _vm.separatorProp(item.prop) }
          },
          [
            item.type === _vm.$tempModel.showType.editor
              ? _c(
                  "el-row",
                  [
                    _c("d2-ueditor", {
                      model: {
                        value: _vm.form[_vm.separatorProp(item.prop)],
                        callback: function($$v) {
                          _vm.$set(_vm.form, _vm.separatorProp(item.prop), $$v)
                        },
                        expression: "form[separatorProp(item.prop)]"
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 15 } },
                      [
                        item.type === _vm.$tempModel.showType.select
                          ? _c(
                              "el-select",
                              {
                                style: {
                                  width: item.exProps.width
                                    ? item.exProps.width
                                    : null
                                },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择",
                                  "allow-create": "",
                                  multiple: item.exProps.multiple ? true : false
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onSelectChange($event, item.prop)
                                  }
                                },
                                model: {
                                  value: _vm.form[_vm.separatorProp(item.prop)],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      _vm.separatorProp(item.prop),
                                      $$v
                                    )
                                  },
                                  expression: "form[separatorProp(item.prop)]"
                                }
                              },
                              _vm._l(item.exProps.data, function(selectItem) {
                                return _c("el-option", {
                                  key: selectItem.key,
                                  attrs: {
                                    label: selectItem.value,
                                    value: selectItem.key
                                  }
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                        item.type === _vm.$tempModel.showType.radio
                          ? _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.form[_vm.separatorProp(item.prop)],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      _vm.separatorProp(item.prop),
                                      $$v
                                    )
                                  },
                                  expression: "form[separatorProp(item.prop)]"
                                }
                              },
                              _vm._l(item.exProps.data, function(selectItem) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: selectItem.key,
                                    attrs: { label: selectItem.key }
                                  },
                                  [_vm._v(_vm._s(selectItem.value))]
                                )
                              }),
                              1
                            )
                          : _vm._e(),
                        item.type === _vm.$tempModel.showType.slider
                          ? _c("el-slider", {
                              model: {
                                value: _vm.form[_vm.separatorProp(item.prop)],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    _vm.separatorProp(item.prop),
                                    $$v
                                  )
                                },
                                expression: "form[separatorProp(item.prop)]"
                              }
                            })
                          : _vm._e(),
                        item.type === _vm.$tempModel.showType.color
                          ? _c("el-color-picker", {
                              model: {
                                value: _vm.form[_vm.separatorProp(item.prop)],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    _vm.separatorProp(item.prop),
                                    $$v
                                  )
                                },
                                expression: "form[separatorProp(item.prop)]"
                              }
                            })
                          : _vm._e(),
                        item.type === _vm.$tempModel.showType.rate
                          ? _c("el-rate", {
                              attrs: {
                                colors: ["#99A9BF", "#F7BA2A", "#FF9900"]
                              },
                              model: {
                                value: _vm.form[_vm.separatorProp(item.prop)],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    _vm.separatorProp(item.prop),
                                    $$v
                                  )
                                },
                                expression: "form[separatorProp(item.prop)]"
                              }
                            })
                          : _vm._e(),
                        item.type === null ||
                        item.type === _vm.$tempModel.showType.text ||
                        item.type === _vm.$tempModel.showType.link
                          ? _c("el-input", {
                              model: {
                                value: _vm.form[_vm.separatorProp(item.prop)],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    _vm.separatorProp(item.prop),
                                    $$v
                                  )
                                },
                                expression: "form[separatorProp(item.prop)]"
                              }
                            })
                          : _vm._e(),
                        item.type === _vm.$tempModel.showType.pwd
                          ? _c("el-input", {
                              attrs: { "show-password": "" },
                              model: {
                                value: _vm.form[_vm.separatorProp(item.prop)],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    _vm.separatorProp(item.prop),
                                    $$v
                                  )
                                },
                                expression: "form[separatorProp(item.prop)]"
                              }
                            })
                          : _vm._e(),
                        item.type === _vm.$tempModel.showType.textarea
                          ? _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: { minRows: 3, maxRows: 6 },
                                "show-word-limit": "",
                                maxlength: item.max
                              },
                              model: {
                                value: _vm.form[_vm.separatorProp(item.prop)],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    _vm.separatorProp(item.prop),
                                    $$v
                                  )
                                },
                                expression: "form[separatorProp(item.prop)]"
                              }
                            })
                          : _vm._e(),
                        item.type === _vm.$tempModel.showType.cascader
                          ? _c("el-cascader", {
                              style: { width: item.exProps.width },
                              attrs: {
                                clearable: true,
                                filterable: true,
                                options: item.exProps.data,
                                props: {
                                  // expandTrigger: 'hover',
                                  multiple: item.exProps.multiple
                                    ? true
                                    : false,
                                  value: "key",
                                  label: "value",
                                  children: "children"
                                }
                              },
                              on: {
                                change: function($event) {
                                  return _vm.onCascaderChange($event, item.prop)
                                }
                              },
                              model: {
                                value: _vm.form[_vm.separatorProp(item.prop)],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    _vm.separatorProp(item.prop),
                                    $$v
                                  )
                                },
                                expression: "form[separatorProp(item.prop)]"
                              }
                            })
                          : _vm._e(),
                        item.type === _vm.$tempModel.showType.icon
                          ? _c("d2-icon-select", {
                              attrs: { "user-input": true },
                              model: {
                                value: _vm.form[_vm.separatorProp(item.prop)],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    _vm.separatorProp(item.prop),
                                    $$v
                                  )
                                },
                                expression: "form[separatorProp(item.prop)]"
                              }
                            })
                          : _vm._e(),
                        item.type === _vm.$tempModel.showType.switch
                          ? _c("el-switch", {
                              attrs: {
                                "active-value": item.exProps.activeValue,
                                "inactive-value": item.exProps.inactiveValue
                              },
                              on: {
                                change: function($event) {
                                  return _vm.onSwitchChange($event, item.prop)
                                }
                              },
                              model: {
                                value: _vm.form[_vm.separatorProp(item.prop)],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    _vm.separatorProp(item.prop),
                                    $$v
                                  )
                                },
                                expression: "form[separatorProp(item.prop)]"
                              }
                            })
                          : _vm._e(),
                        item.type === _vm.$tempModel.showType.date ||
                        item.type === _vm.$tempModel.showType.datetime ||
                        item.type === _vm.$tempModel.showType.dateTimeRange
                          ? _c("el-date-picker", {
                              attrs: {
                                type: item.type,
                                "value-format":
                                  item.type === _vm.$tempModel.showType.date
                                    ? "yyyy-MM-dd"
                                    : "yyyy-MM-dd HH:mm:ss",
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                placeholder: "选择日期"
                              },
                              model: {
                                value: _vm.form[_vm.separatorProp(item.prop)],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    _vm.separatorProp(item.prop),
                                    $$v
                                  )
                                },
                                expression: "form[separatorProp(item.prop)]"
                              }
                            })
                          : _vm._e(),
                        item.type === _vm.$tempModel.showType.avatar
                          ? _c(
                              "el-upload",
                              {
                                class: { hide: !_vm.isFilePlusShow[item.prop] },
                                attrs: {
                                  drag: false,
                                  multiple: true,
                                  action: "",
                                  accept: item.exProps.fileType,
                                  limit: 100,
                                  "http-request": function(option) {
                                    return _vm.onUpload(
                                      option,
                                      item.exProps.exData
                                    )
                                  },
                                  "before-upload": function(file) {
                                    return _vm.onBeforeUpload(file, item)
                                  },
                                  "on-error": function(err, file, fileList) {
                                    return _vm.onError(
                                      err,
                                      file,
                                      fileList,
                                      item
                                    )
                                  },
                                  "on-success": function(
                                    response,
                                    file,
                                    fileList
                                  ) {
                                    return _vm.onSuccess(
                                      response,
                                      file,
                                      fileList,
                                      item
                                    )
                                  },
                                  "on-remove": function(file, fileList) {
                                    return _vm.onRemove(file, fileList, item)
                                  },
                                  "file-list":
                                    _vm.form[
                                      _vm.separatorProp(item.prop) + "_files"
                                    ]
                                }
                              },
                              [
                                _c("el-button", { attrs: { size: "mini" } }, [
                                  _vm._v("点击上传")
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        item.type === _vm.$tempModel.showType.image ||
                        item.type === _vm.$tempModel.showType.images ||
                        item.type === _vm.$tempModel.showType.video ||
                        item.type === _vm.$tempModel.showType.audio
                          ? _c(
                              "el-upload",
                              {
                                class: { hide: !_vm.isFilePlusShow[item.prop] },
                                attrs: {
                                  drag: false,
                                  multiple: item.exProps.maxNum > 1,
                                  action: "",
                                  accept: item.exProps.fileType,
                                  "show-file-list": true,
                                  limit: item.exProps.maxNum,
                                  "http-request": function(option) {
                                    return _vm.onUpload(
                                      option,
                                      item.exProps.exData
                                    )
                                  },
                                  "before-upload": function(file) {
                                    return _vm.onBeforeUpload(file, item)
                                  },
                                  "on-error": function(err, file, fileList) {
                                    return _vm.onError(
                                      err,
                                      file,
                                      fileList,
                                      item
                                    )
                                  },
                                  "on-success": function(
                                    response,
                                    file,
                                    fileList
                                  ) {
                                    return _vm.onSuccess(
                                      response,
                                      file,
                                      fileList,
                                      item
                                    )
                                  },
                                  "on-remove": function(file, fileList) {
                                    return _vm.onRemove(file, fileList, item)
                                  },
                                  "file-list":
                                    _vm.form[
                                      _vm.separatorProp(item.prop) + "_files"
                                    ],
                                  "list-type":
                                    item.type ===
                                      _vm.$tempModel.showType.video ||
                                    item.type === _vm.$tempModel.showType.audio
                                      ? null
                                      : "picture-card"
                                }
                              },
                              [
                                item.type === _vm.$tempModel.showType.video ||
                                item.type === _vm.$tempModel.showType.audio
                                  ? _c(
                                      "el-button",
                                      { attrs: { size: "mini" } },
                                      [_vm._v("点击上传")]
                                    )
                                  : _vm._e(),
                                item.type !== _vm.$tempModel.showType.video &&
                                item.type !== _vm.$tempModel.showType.audio
                                  ? _c("i", {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon"
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("el-col", { attrs: { offset: 1, span: 8 } }, [
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#969696", "font-size": "13px" }
                        },
                        [_vm._v(_vm._s(item.desc ? item.desc : ""))]
                      )
                    ])
                  ],
                  1
                )
          ],
          1
        )
      }),
      _c("input", { attrs: { type: "hidden", id: "hfText" } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }