import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from '@/components/rw/tempTable/index';
import tempSearch from '@/components/rw/tempSearch/index';
import modifyPwd from './modifyPwd';
import md5 from 'js-md5';
import { accountList, roleList, accountAdd, accountDel } from '../../../../api/apis';
import aes from "@/plugin/aes.js";
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    modifyPwd: modifyPwd
  },
  data: function data() {
    var _this = this;

    return {
      roleData: [],
      searchModel_1000: {
        'del': false,
        'id': 'id_1000',
        'operateData': [{
          'icon': 'el-icon-search',
          'tag': 'search',
          'title': '搜索',
          'type': 'primary'
        }],
        'searchData': [{
          'children': [{
            'field': [],
            'title': '全部',
            'type': 'button'
          }, {
            'field': [{
              'key': '',
              'value': ''
            }],
            'title': '分类一',
            'type': 'button'
          }, {
            'field': [{
              'key': '',
              'value': ''
            }],
            'title': '分类二',
            'type': 'button'
          }],
          'id': '0',
          'remote': true,
          'remoteType': 0,
          'select': 0,
          'title': '按角色',
          'type': 0,
          'remoteFunc': ['modelFunc_0']
        }, {
          'children': [{
            'field': [{
              'desc': '账号',
              'key': 'account',
              'value': ''
            }, {
              'desc': '姓名',
              'key': 'real_name',
              'value': ''
            }],
            'placeholder': '请输入编号、姓名',
            'type': 'input-complex'
          }],
          'id': '1',
          'remote': false,
          'remoteType': 0,
          'select': 0,
          'title': '按账号姓名',
          'type': 2,
          'remoteFunc': []
        }],
        'type': 'searchTemplate'
      },
      tableModel_1001: {
        'border': true,
        'del': false,
        'headerButton': [{
          'id': '',
          'tag': 'add',
          'title': '添加',
          'type': 'primary'
        }],
        'id': 'id_1001',
        'rowButton': [{
          'id': '',
          'tag': 'edit',
          'title': '编辑',
          'type': 'primary'
        }, {
          'id': '',
          'tag': 'del',
          'title': '删除',
          'type': 'danger'
        }, {
          'id': '',
          'tag': 'default',
          'title': '修改密码',
          'type': null
        }],
        'showPagination': true,
        'showSelection': false,
        'showSummary': false,
        'stripe': false,
        'tableCascaderProps': {},
        'tableModel': [{
          'add': 0,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': 'ID',
          'required': 0,
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '80',
          'prop': 'id'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '账号',
          'required': 1,
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'account'
        }, {
          'add': 1,
          'desc': '',
          'detail': 0,
          'excel': 0,
          'groupId': '',
          'label': '密码',
          'required': 1,
          'rules': [],
          'show': 0,
          'type': 'pwd',
          'width': '200',
          'prop': 'pwd'
        }, {
          'add': 1,
          'desc': '',
          'detail': 0,
          'groupId': '',
          'label': '确认密码',
          'required': 1,
          'rules': [],
          'show': 0,
          'type': 'pwd',
          'width': null,
          'prop': 'confirmPwd'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '姓名',
          'required': 1,
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'real_name'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '角色',
          'required': 1,
          'rules': [],
          'show': 1,
          'type': 'select',
          'width': '120',
          'prop': 'roles'
        }, {
          'add': 0,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '上次登录ip',
          'required': 0,
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '200',
          'prop': 'last_ip'
        }, {
          'add': 0,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '上次登录时间',
          'required': 0,
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '150',
          'prop': 'last_time'
        }, {
          'add': 0,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '添加时间',
          'required': 0,
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '150',
          'prop': 'add_time'
        }, {
          'add': 0,
          'defaultValue': null,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '登录次数',
          'required': 0,
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '120',
          'prop': 'login_count'
        }, {
          'add': 0,
          'detail': 0,
          'excel': 0,
          'groupId': '',
          'label': '是否超级管理',
          'required': 0,
          'rules': [],
          'show': 0,
          'type': 'switch',
          'width': '100',
          'prop': 'level'
        }, {
          'add': 0,
          'defaultValue': 1,
          'detail': 1,
          'excel': 0,
          'groupId': '',
          'label': '是否禁用',
          'required': 0,
          'rules': [],
          'show': 1,
          'type': 'switch',
          'width': '100',
          'prop': 'status'
        }],
        'tableName': 't_system_admin',
        'tableSelectProps': {
          'roles': {
            'data': '[{"key":"","value":""}]',
            'multiple': 0,
            'remote': true,
            'remoteFunc': 'tableFunc_select_roles'
          }
        },
        'tableSwitchProps': {
          'level': {
            'activeValue': 1,
            'inactiveValue': 0,
            'integer': 1
          },
          'status': {
            'activeValue': 0,
            'id': null,
            'inactiveValue': 1,
            'integer': 1,
            'relation': false,
            'relationId': ''
          }
        },
        'title': '账户',
        'type': 'tableTemplate',
        'tableRadioProps': {}
      },
      remoteFunc: {
        modelFunc_0: function modelFunc_0(tag, relationData, resolve) {
          //筛选项模型数组
          roleList({}).then(function _callee(res) {
            var data, i;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    data = [];
                    data.push({
                      type: _this.$tempModel.searchType.button,
                      title: '全部',
                      field: []
                    });

                    for (i in res) {
                      data.push({
                        type: _this.$tempModel.searchType.button,
                        title: res[i].role_name,
                        field: [{
                          key: 'roles',
                          value: res[i].id
                        }]
                      });
                    }

                    resolve(tag, data);

                  case 4:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        },
        tableFunc_select_roles: function tableFunc_select_roles(tag, relationData, resolve) {
          //筛选项模型数组
          roleList({}).then(function _callee2(res) {
            var data, i;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    data = [];

                    for (i in res) {
                      data.push({
                        key: res[i].id,
                        value: res[i].role_name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      accountList(params).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve({
                  total: 0,
                  data: res
                });

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      } else {
        if (params.pwd != params.confirmPwd) {
          this.$message.error('两次密码不一致');
          resolve(true);
          return;
        }

        params.pwd = aes.encrypt(params.pwd);
      }

      params.tcode = 'test';
      accountAdd(params).then(function _callee4(res) {
        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context4.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      accountDel({
        id: ids[0]
      }).then(function _callee5(res) {
        return regeneratorRuntime.async(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context5.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      //点击列表按钮回调
      if (btnIndex === 2) {
        this.$refs['pwd'].show(row.id, row.account, row.real_name);
      }
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {
      //列表中switch变化事件
      accountAdd({
        id: row.id,
        status: val
      }).then(function _callee6(res) {
        return regeneratorRuntime.async(function _callee6$(_context6) {
          while (1) {
            switch (_context6.prev = _context6.next) {
              case 0:
              case "end":
                return _context6.stop();
            }
          }
        });
      });
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = data.header;
        var results = data.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    }
  }
};