'use strict';

import "core-js/modules/es.function.name";
import "core-js/modules/es.string.replace";
import qiniu from './qiniu';
import aliyun from './aliyun';
import server from './server';
import setting from '@/setting';
import Utils from '@/plugin/upload/aliyun/utils';
export default {
  upload: function upload(option) {
    var exData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      type: 'default'
    };
    var file = option.file;
    var fileName = this.randomFileName(file);
    console.log('aaaaaaaaaaa', fileName);

    if (setting.ossConfig.platform === 0 || exData.type === 'video') {
      fileName = exData.type + '/' + fileName;
      console.log('fileNamefileName', fileName);
      aliyun.uploadFile(file, fileName, function (p) {
        option.onProgress({
          percent: Math.floor(p * 100)
        });
      }, function (ret) {
        var response = {};

        if (ret.res.statusCode === 200) {
          response.url = setting.ossConfig.ossPath + ret.name;
          console.log('  response.url', response.url);
          option.onSuccess(response);
        } else {
          option.onError(ret.res.statusCode + '');
        }
      }, function (err) {
        option.onError(err);
      });
    } else if (setting.ossConfig.platform === 1) {} else if (setting.ossConfig.platform === 2) {
      server.uploadFile(file, exData, function (ret) {
        var response = {};
        response.url = ret[0].url;
        option.onSuccess(response);
      }, function (err) {
        option.onError({
          code: -100,
          msg: err.msg
        });
      });
    }
  },
  randomFileName: function randomFileName(file) {
    var fileName = file.name.replace(/\s*/g, ""); // fileName =  encodeURIComponent(fileName)

    console.log('aaaaaaa', fileName);
    return fileName; // let dateTime = Utils.dateFormat(new Date(), 'yyyyMMddhhmmss') // 当前时间
    // let randomStr = this.randomString(4)//  4位随机字符串
    // let extensionName = file.name.substr(file.name.indexOf('.')) // 文件扩展名
    // let fileName = setting.ossConfig.ossDic + dateTime + '_' + randomStr + extensionName
    // return fileName
  },
  randomString: function randomString(num) {
    var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    var res = '';

    for (var i = 0; i < num; i++) {
      var id = Math.ceil(Math.random() * 35);
      res += chars[id];
    }

    return res;
  }
};