import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.parse-int";
import "core-js/modules/es.string.replace";
import "core-js/modules/es.string.link";
import "core-js/modules/web.dom-collections.for-each";
import _typeof from "/Users/suifeng/Downloads/sxGameWeb/node_modules/@babel/runtime/helpers/esm/typeof";
import formRules from '@/components/rw/formRules';
export default {
  tempType: {
    //模板类型
    search: 'searchTemplate',
    table: 'tableTemplate',
    form: 'formTemplate',
    chart: 'chartTemplate'
  },
  buttonType: ['default', 'primary', 'success', 'info', 'warning', 'danger'],
  //按钮样式类型
  buttonTag: {
    //按钮功能标识
    detail: 'detail',
    edit: 'edit',
    del: 'del',
    mulDel: 'mul-del',
    add: 'add',
    rowAdd: 'row-add',
    import: 'import',
    export: 'export',
    search: 'search',
    default: 'default'
  },
  searchType: {
    //搜索子组件类型
    input: 'input',
    inputComplex: 'input-complex',
    inputMulComplex: 'input-mul-complex',
    button: 'button',
    select: 'select',
    buttonNumber: 'button-number',
    buttonNumberMajor: 'button-number-major',
    customDateRange: 'custom-date-range',
    customDatetimeRange: 'custom-datetime-range',
    customTimeRange: 'custom-time-range'
  },
  chartType: {
    line: '折线图',
    histogram: '柱状图',
    bar: '条形图',
    pie: '饼状图',
    ring: '环状图',
    waterfall: '瀑布图',
    funnel: '漏斗图',
    radar: '雷达图' // heatmap:'热力图',
    // scatter:'散点图',
    // candle:'K线图',
    // gauge:'仪表盘',
    // tree:'树图',

  },
  showType: {
    //列表可现实的类型
    text: 'text',
    icon: 'icon',
    switch: 'switch',
    image: 'image',
    select: 'select',
    avatar: 'avatar',
    images: 'images',
    video: 'video',
    date: 'date',
    datetime: 'datetime',
    dateTimeRange: 'datetimerange',
    pwd: 'pwd',
    editor: 'editor',
    link: 'link',
    cascader: 'cascader',
    textarea: 'textarea',
    rate: 'rate',
    slider: 'slider',
    radio: 'radio',
    color: 'color',
    audio: 'audio',
    custom: 'custom',
    isText: function isText(type) {
      var types = [this.text, this.date, this.datetime, this.dateTimeRange, this.color, this.slider];

      if (types.indexOf(type) === -1) {
        return false;
      }

      return true;
    },
    isSwitch: function isSwitch(type) {
      var types = [this.switch];

      if (types.indexOf(type) === -1) {
        return false;
      }

      return true;
    },
    isAudio: function isAudio(type) {
      var types = [this.audio];

      if (types.indexOf(type) === -1) {
        return false;
      }

      return true;
    },
    isRadio: function isRadio(type) {
      var types = [this.radio];

      if (types.indexOf(type) === -1) {
        return false;
      }

      return true;
    },
    isImage: function isImage(type) {
      var types = [this.image];

      if (types.indexOf(type) === -1) {
        return false;
      }

      return true;
    },
    isCustom: function isCustom(type) {
      var types = [this.custom];

      if (types.indexOf(type) === -1) {
        return false;
      }

      return true;
    },
    isImages: function isImages(type) {
      var types = [this.images];

      if (types.indexOf(type) === -1) {
        return false;
      }

      return true;
    },
    isAvatar: function isAvatar(type) {
      var types = [this.avatar];

      if (types.indexOf(type) === -1) {
        return false;
      }

      return true;
    },
    isLink: function isLink(type) {
      var types = [this.link];

      if (types.indexOf(type) === -1) {
        return false;
      }

      return true;
    },
    isVideo: function isVideo(type) {
      var types = [this.video];

      if (types.indexOf(type) === -1) {
        return false;
      }

      return true;
    },
    isSelect: function isSelect(type) {
      var types = [this.select];

      if (types.indexOf(type) === -1) {
        return false;
      }

      return true;
    },
    isIcon: function isIcon(type) {
      var types = [this.icon];

      if (types.indexOf(type) === -1) {
        return false;
      }

      return true;
    },
    isTextarea: function isTextarea(type) {
      var types = [this.textarea, this.editor];

      if (types.indexOf(type) === -1) {
        return false;
      }

      return true;
    },
    isRate: function isRate(type) {
      var types = [this.rate];

      if (types.indexOf(type) === -1) {
        return false;
      }

      return true;
    }
  },
  rules: formRules,
  pagination: {
    limit: 20,
    sizes: [20, 50, 100, 200, 300, 500]
  },
  oss: {
    path: 'http://ysb-edu-video.oss-cn-shanghai.aliyuncs.com/'
  },
  deepCopy: function deepCopy(obj) {
    var _this = this;

    var cache = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    //深拷贝
    // typeof {} => 'object'
    if (obj === null || _typeof(obj) !== 'object') {
      return obj;
    } // 如果传入的对象与缓存的相等, 则递归结束, 这样防止循环

    /**
     * 类似下面这种
     * var a = {b:1}
     * a.c = a
     * 资料: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
     */


    var hit = cache.filter(function (c) {
      return c.original === obj;
    })[0];

    if (hit) {
      return hit.copy;
    }

    var copy = Array.isArray(obj) ? [] : {}; // 将copy首先放入cache, 因为我们需要在递归deepCopy的时候引用它

    cache.push({
      original: obj,
      copy: copy
    });
    Object.keys(obj).forEach(function (key) {
      copy[key] = _this.deepCopy(obj[key], cache);
    });
    return copy;
  },
  powerOf: function powerOf(tid) {
    //是否有权限
    return true;
  },
  treeToList: function treeToList(tree, childrenTag, mapRow) {
    var ary = [];

    var addRow = function addRow(obj, parent) {
      var item = JSON.parse(JSON.stringify(obj));

      if (mapRow) {
        mapRow(parent, item);
      }

      delete item[childrenTag];
      ary.push(item);
      var sub = obj[childrenTag];

      for (var i in sub) {
        addRow(sub[i], item);
      }
    };

    for (var i in tree) {
      addRow(tree[i], null);
    }

    return ary;
  },
  //复制到剪切板
  clipCopy: function clipCopy(content) {
    var aux = document.createElement('input');
    var msg = '';

    if (typeof content === 'string') {
      msg = content;
    } else {
      msg = JSON.stringify(content);
    }

    aux.setAttribute('value', msg);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand('copy');
    document.body.removeChild(aux);
  },
  //去掉空格
  trim: function trim(str) {
    return str.replace(/ /g, "");
  },
  //随机数
  randomNum: function randomNum(minNum, maxNum) {
    switch (arguments.length) {
      case 1:
        return parseInt(Math.random() * minNum + 1, 10);
        break;

      case 2:
        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
        break;

      default:
        return 0;
        break;
    }
  },
  writeAndDown: function writeAndDown(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
};